import logo from "./logo.webp"

const  clientData = {
    client_entity: 24,
    attorney_firm: 'westbrooklawgroup.com',
    attorney_name: 'Brent Westbrook',
    attorney_number: '(636) 493-9231 ',
    attorney_email: 'brent@westbrooklawgroup.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_westbrook_law_group+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#0F2D5B',
    siteLink: 'http://westbrooklawgroup.com',
    logo
}

export default clientData